/* userInterface */
import request from '@/utils/request.js'

/* LoginReq */
export const SmsReq = (data) => {
  return request({
    method: 'POST',
    url: '/customerapi/V2/auth/sms',
    data,
  })
}

/** SMS login **/
export const VerificationReq = (data) => {
  return request({
    method: 'POST',
    url: '/customerapi/V2/auth/verification',
    data,
  })
}

/** my profile  **/
export const SignupReq = (data) => {
  return request({
    method: 'POST',
    url: '/customerapi/V2/auth/profile',
    data,
  })
}

export const GetProfileReq = () => {
  return request({
    method: 'GET',
    url: '/customerapi/V2/auth/profile',
  })
}


/** Check phone number  **/
export const PhonenumberReq = (data) => {
  return request({
    method: 'POST',
    url: '/customerapi/V2/auth/phonenumber',
    data,
  })
}


/** Login with password  **/
export const PassverificationReq = (data) => {
  return request({
    method: 'POST',
    url: '/customerapi/V2/auth/passverification',
    data,
  })
}


/** Update password  **/
export const PasswordReq = (data) => {
  return request({
    method: 'POST',
    url: '/customerapi/V2/auth/password',
    data,
  })
}